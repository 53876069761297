import React, { useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom'

const startColor = '00FF00';
const endColor = 'FF0000';

function hex(x) {
    x = x.toString(16);
    return (x.length === 1) ? '0' + x : x;
};

function computeColor(ratio){
    let r = Math.ceil(parseInt(startColor.substring(0,2), 16) * ratio + parseInt(endColor.substring(0,2), 16) * (1-ratio));
    let g = Math.ceil(parseInt(startColor.substring(2,4), 16) * ratio + parseInt(endColor.substring(2,4), 16) * (1-ratio));
    let b = Math.ceil(parseInt(startColor.substring(4,6), 16) * ratio + parseInt(endColor.substring(4,6), 16) * (1-ratio));
    return "#" + hex(r) + hex(g) + hex(b);
}

const multiplier = 91367
const modulus = 27239

function simpleHash(num){
    let d = new Date(); // add in a little extra security s.t. urls don't work unless the same day of month
    return multiplier * (num * d.getDate()) % modulus
}

export function Countdown(props){
    // countdown every second
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        let timeout = setTimeout(() => setRedirect(true), 1000);
        return () => {
            clearTimeout(timeout);
            setRedirect(false);
        };
    });

    // check to make sure path is valid, otherwise restart countdown
    let pathnameParts = props.history.location.pathname.split('/');
    let test = pathnameParts.pop()
    let count = Number(test);
    if(
        pathnameParts.length !== 2 || 
        !Number.isInteger(count) || 
        count < 0 || 
        count > props.countdownStart.start ||
        (
            count < props.countdownStart.start && 
            simpleHash((count + 1)) - Number(props.history.location.hash.substring(1)) !== 0
        )    
    ){
        return <Redirect to={props.countdownStart.path}/>;
    }

    // the surprise!
    if(count === 0){
        window.location.href="https://moc.alexfu.ufxela.com";
    }
    
    // compute color of countdown display
    const style = {
        color: computeColor(count / props.countdownStart.start)
    };

    let redirectPath = "/" + pathnameParts.pop() + "/" + (count-1) + "#" + simpleHash(count);
    return redirect ? <Redirect to={redirectPath} /> : <h1 style={style}>{count}</h1>;
}
