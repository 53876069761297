import './App.css';

import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Countdown } from './components/countdown';
import React from 'react';

const start = 100;
const countdownStart = {
  start: start,
  path: "/countdown/" + start
}

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <Router>
            <Switch>
              <Route path="/countdown" render={(routeProps) => <Countdown {...routeProps} countdownStart={countdownStart}/>} />
              <Redirect to={countdownStart.path}/>
            </Switch>
        </Router>
      </header>
    </div>
  );
}

export default App;
